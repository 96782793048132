<template>
  <div>
    <breadCrumb title="Return Policy" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="p-4 bg-white rounded shadow-sm overflow-hidden mw-100 text-left" >
          <template v-if="content.length > 0">
            <div v-html="content"></div>
          </template>
          <template v-else>
            <h1>Return Policy for {{ appName }} </h1>

            <p>Thank you for shopping at {{ appName }}. We are committed to ensuring your satisfaction with our products. If you are not entirely satisfied with your purchase, we're here to help.</p>

            <h2>1. Returns</h2>
            <p>1.1. You have [7] days to return an item from the date you received it.</p>
            <p>1.2. To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>

            <h2>2. Refunds</h2>
            <p>2.1. Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you on the status of your refund after inspecting the item.</p>
            <p>2.2. If your return is approved, we will initiate a refund to your original method of payment.</p>
            <p>2.3. You will receive the credit within a certain amount of days, depending on your card issuer's policies.</p>

            <h2>3. Shipping</h2>
            <p>3.1. You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.</p>
            <p>3.2. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>

            <h2>4. Contact Us</h2>
            <p>If you have any questions on how to return your item to us, please contact us at suoper@tk-shop.pro.</p>

            <p>[End of Return Policy]</p>
          </template>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import { getArticleLists } from '@/api/index'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: 'Return Policy',
          path: ''
        },
      ],
      content: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName;
    }
  },
  mounted() {
    this.GetArticleData()
  },
  methods: {
    GetArticleData() {
      let f = new FormData()
      f.append('Type', '2')
      getArticleLists(f).then(res => {
        if(res.data.Items.length > 0){
          this.content = res.data.Items[0].Content
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>